import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  makeChatCall,
  sendPushNotification,
  stopChatCall,
  mutedAudioChatCall,
  mutedVideoChatCall,
  connectCallWS,
  disconnectCallWS,
  sendRejectMessage,
  setRejected,
} from "../../../../redux/chat/actions";
import ConsultationCard from "../../ConsultattionCard/ConsultationCard";
import { HelpModal } from "./";

import info from "../../../../style/icons/InfoSign/info-sign-blue.svg";
import chatSearch from "../../../../style/icons/Search/searche-blue-icon.svg";
import video from "../../../../style/icons/VideoCall/video-call.svg";
import muted_audio from "../../../../style/icons/Chat/muted_audio.svg";
import call from "../../../../style/icons/PhoneCall/phone-call.svg";
import microphone from "../../../../style/icons/Chat/microphone.svg";
import muted_video from "../../../../style/icons/Chat/muted_video.svg";
import stop_call from "../../../../style/icons/Chat/stop_call.svg";
import make_full_screen from "../../../../style/icons/Chat/make_full_screen_video.svg";
import close_full_screen from "../../../../style/icons/Chat/close_full_screen_video.svg";
import Carousel from "../../ui/Carousel/Carousel";
import { getConsultLoading, getCurrentConsultPatientSelector } from "../../../../redux/consultations/selectors";

const CallTools = ({
  makeChatCall,
  connectCallWS,
  disconnectCallWS,
  doctor,
  status,
  isAudioCall,
  isVideoCall,
  stopChatCall,
  bothInChat,
  mutedAudioChatCall,
  mutedVideoChatCall,
  patientID,
  consultId,
  docSpecialty,
  sendPushNotification,
  sendRejectMessage,
  setRejected,
  isRejected,
  currentPatient,
  currentConsultation,
  consultLoading,
  role,
}) => {
  const { t } = useTranslation();
  const [isOpenHelpModal, toggleHelpModal] = useState(false);
  const [mutedAudio, setMutedAudio] = useState(false);
  const [mutedVideo, setMutedVideo] = useState(false);
  const [videoFullScreen, setVideoFullScreen] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const partnerVideoRef = useRef();
  const userVideoRef = useRef();
  const userAudioRef = useRef();
  const isInProcessStatus = status === "in_process";
  const isPatient = Number(bothInChat) > 1;
  const isCall = isVideoCall || isAudioCall;

  React.useEffect(() => {
    if (isVideoCall || isAudioCall) {
      setIsCalling(false);
    }
  }, [isVideoCall, isAudioCall]);

  React.useEffect(() => {
    setRejected(false)
    return () => {
      setIsCalling(false)
      setRejected(false)
    }
  }, [])

  React.useEffect(() => {
    if (isRejected) {
      setIsCalling(false)
      setRejected(false)
    }
  }, [isRejected])

  const handleMutedVideoCallChat = () => {
    mutedVideoChatCall();
    setMutedVideo((isMuted) => !isMuted);
  };
  const handleMutedAudioCallChat = () => {
    mutedAudioChatCall();
    setMutedAudio((isMuted) => !isMuted);
  };

  const openFullScreen = () => setVideoFullScreen(true);
  const closeFullScreen = () => setVideoFullScreen(false);
  const handleClickPhoneIcon = (isVideo) => {
    if (isVideoCall || isAudioCall || isCalling) {
      console.log("STOP CALLING");
      if (isCalling) {
        sendRejectMessage()
      }
      stopChatCall({ rejectFromOpponent: false });
      setVideoFullScreen(false);
      setIsCalling(false);
      return;
    }

    setIsCalling(true);
    callPeer(isVideo);
    handelSendPushNotification(isVideo);
  };

  const callPeer = async (isVideo) => {
    await connectCallWS({
      userId: patientID,
      doctorId: doctor.doctor.id,
      doctorSpecialtyId: docSpecialty.id,
      token: localStorage.getItem("token"),
    });

    makeChatCall({
      userVideo: userVideoRef,
      userAudio: userAudioRef,
      isVideo,
      doctor,
      docSpecialty,
      patientId: patientID,
      consultId: consultId,
      partnerVideo: partnerVideoRef,
    });
  };

  useEffect(() => disconnectCallWS, []);

  const PartnerVideo = (
    <video
      playsInline
      ref={partnerVideoRef}
      autoPlay
      className={`myVideo ${isCall ? "" : "hidden"}`}
    />
  );

  const UserVideo = (
    <video
      playsInline
      ref={userVideoRef}
      autoPlay
      className={`opponentVideo ${isCall ? "" : "hidden"}`}
    />
  );

  const UserAudio = (
    <audio
      ref={userAudioRef}
      autoPlay
      volume="true"
      style={{width: "0px", opacity: 0, height: "0px"}}
    />
  );

  const handelSendPushNotification = (isVideo) => {
    // callPeer(isVideo);
    sendPushNotification({
      isVideo,
      doctor,
      docSpecialty,
      patientId: patientID,
      consultId,
    });
  };

  return (
    <>
      <HelpModal
        isOpenHelpModal={isOpenHelpModal}
        toggleHelpModal={toggleHelpModal}
      />
      <div className="DoctorChatTitle">
        <p className="chatTitle">{t("chat")}</p>
        {isCalling && <Carousel/>}
        {currentConsultation && currentConsultation.id == consultId && (
          <div className="connectToolBar">
            {role === "doctor" && doctor && isInProcessStatus && (
              <img
                className="ds"
                src={(isVideoCall || isAudioCall) || isCalling ? stop_call : call}
                alt=""
                onClick={() => handleClickPhoneIcon(false)}
              />
            )}
            {role === "doctor" && doctor && isInProcessStatus && !(isVideoCall || isAudioCall) && (
              <img
                aria-disabled={isPatient}
                src={video}
                alt=""
                onClick={() => handleClickPhoneIcon(true)}
              />
            )}
            {/* {doctor &&
            isInProcessStatus &&
            (isPatient ? (
              <img
                className="ds"
                src={isVideoCall ? stop_call : call}
                alt=""
                onClick={() => handleClickPhoneIcon(false)}
              />
            ) : (
              <img
                className="disabled"
                src={isVideoCall ? stop_call : call}
                alt=""
                onClick={() => handelSendPushNotification(false)}
              />
            ))} */}
            {/* {doctor && isInProcessStatus && !(isVideoCall || isAudioCall) && (
            <>
              {isPatient ? (
                <img
                  aria-disabled={isPatient}
                  src={video}
                  alt=""
                  onClick={() => handleClickPhoneIcon(true)}
                />
              ) : (
                <img
                  className="disabled"
                  src={video}
                  alt=""
                  onClick={() => handelSendPushNotification(false)}
                />
              )}
            </>
          )} */}
            {(isVideoCall || isAudioCall) && (
              <img
                src={mutedAudio ? muted_audio : microphone}
                alt=""
                onClick={handleMutedAudioCallChat}
              />
            )}
            {isVideoCall && (
              <img
                src={mutedVideo ? muted_video : video}
                alt=""
                onClick={handleMutedVideoCallChat}
              />
            )}
            <img src={chatSearch} alt="" />
            <img
              src={info}
              alt="info-icon"
              onClick={() => toggleHelpModal(true)}
            />
          </div>
        )}
      </div>
      {console.log("IS AUDIO CALL", {isAudioCall, userAudioRef})}
      {/* {isAudioCall && UserVideo} */}
      {/* {isAudioCall && UserAudio} */}
      <div className={`videoCall ${videoFullScreen ? "fullscreen" : ""}`}>
        {!isAudioCall && PartnerVideo}
        {isVideoCall && UserVideo}
        {isAudioCall && UserAudio}
        {isVideoCall && (
          <div className="icon-buttons">
            {!videoFullScreen && isInProcessStatus && (
              <img
                className="icon-button"
                src={make_full_screen}
                alt=""
                onClick={openFullScreen}
              />
            )}
            {videoFullScreen && (
              <>
                <img
                  className="icon-button"
                  src={isVideoCall ? stop_call : call}
                  alt=""
                  onClick={handleClickPhoneIcon}
                />
                <img
                  className="icon-button"
                  src={mutedAudio ? muted_audio : microphone}
                  alt=""
                  onClick={handleMutedAudioCallChat}
                />
                <img
                  className="icon-button"
                  src={mutedVideo ? muted_video : video}
                  alt=""
                  onClick={handleMutedVideoCallChat}
                />
                <img
                  className="icon-button"
                  src={close_full_screen}
                  alt=""
                  onClick={closeFullScreen}
                />
              </>
            )}
          </div>
        )}
        {videoFullScreen && (
          <div className="mr-left-20">
            <ConsultationCard loading={true} setLoading={() => {}} />
          </div>
        )}
      </div>
    </>
  );
};
const mapStateToProps = ({ chat, ...state }) => ({
  isVideoCall: chat.isVideoCall,
  isAudioCall: chat.isAudioCall,
  bothInChat: chat.countMembersInRoom,
  isRejected: chat.isRejected,
  currentPatient: getCurrentConsultPatientSelector(state),
  consultLoading: getConsultLoading(state),
});

export default connect(mapStateToProps, {
  mutedAudioChatCall,
  mutedVideoChatCall,
  stopChatCall,
  makeChatCall,
  sendPushNotification,
  connectCallWS,
  disconnectCallWS,
  sendRejectMessage,
  setRejected,
})(CallTools);
