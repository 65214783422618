import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Form, getFormValues, reduxForm, Field } from "redux-form";
import { useTranslation } from "react-i18next";

import Button from "../../components/ui/Button/Button";

import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";

import plus from "../../../style/icons/PlusInCircle/plus.svg";
import shevroRight from "../../../style/icons/ShevronRight/shevron.svg";
import close from "../../../style/icons/Close/close-icon.svg";
import arrowLeft from "../../../style/icons/ArrowLeftLight/arrow-left.svg";
import trash from "../../../style/icons/Trash/trash.svg";
import docAvatar from "../../../style/icons/DoctorAvatar/avatar.svg";
import home from "../../../style/icons/Home/home.svg";
import greyPlus from "../../../style/icons/PlusInCircle/plus-grey.svg";
import support from "../../../style/icons/Headphones/headphones.svg";
// import home from "../../../style/icons/Home/home.svg";
import supportOnline from "../../../style/icons/Headphones/headphones_26x26.svg";
import supportHome from "../../../style/icons/Home/home_28x28.svg";

import { notNegative, voidRequired } from "../../../helpers/validate";

import FileUploader from "../../components/formComponent/PhotoPassUploader/PhotoPassUploader";

import "./newSpecializationForm.scss";

import Input from "../../components/formComponent/Input/Input";
import TextArea from "../../components/formComponent/TextArea/TextArea";
import Checkbox from "../../components/formComponent/Checkbox/Checkbox";
import {
  editSpecialization,
  addSpecialization,
  getAllSpecializations,
  deleteSpecializations,
  setPagination,
  updateHomePrices,
} from "../../../redux/specializations/actions";
import api from "../../../api";
import Select from "../../components/formComponent/Select/Select";
import { getDistricts, getDistrictsPrices, getProvinces } from "../../../redux/shedule/actions";
import { getDistrictsSelector, getLoading, getPricesSelector, getProvincesSelector } from "../../../redux/shedule/selectors";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import FilterSelect from "../../components/formComponent/FilterSelect/FilterSelect";
import ToggleImg from "../../components/formComponent/ToggleImg/ToggleImg";
import activeDocIcon from "../../../style/icons/Doc/doc-blue.svg";
import inActiveDocIcon from "../../../style/icons/Doc/doc-grey.svg";
import activeOperatorIcon from "../../../style/icons/Operator/operator-blue.svg";
import inActiveOperatorIcon from "../../../style/icons/Operator/operator-grey.svg";
import Toggle from "../../components/formComponent/Toggle/Toggle";
import Spinner from "../../components/ui/Spinner/Spinner";

const NewSpecializationForm = ({
  edit,
  invalid,
  setEditingOff,
  resetInitialValues,
  setInitalValues,
  initialValues,
  handleSubmit,
  editSpecialization,
  addSpecialization,
  reset,
  formValues,
  getAllSpecializations,
  deleteSpecializations,
  blob,
  setBlob,
  addingImg,
  setAddingImg,
  current_page,
  filters,
  setFilters,
  setPagination,
  getProvinces,
  getDistricts,
  provinces,
  districts,
  districtPrices,
  getDistrictsPrices,
  loading,
  updateHomePrices,
}) => {
  const { t } = useTranslation();
  const [deleting, setDeleting] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false)
  const [mode, setMode] = useState('online')

  const closeEditing = () => {
    setDeleting(false);
    resetInitialValues();
    reset();
    setEditingOff();
  };

  console.log(districtPrices);

  useEffect(() => {
    if (districtPrices.length > 0) {
      districtPrices.forEach(el => {
        formValues[el.district.id] = el.price
      })
    }
  }, [districtPrices])
  
  console.log(formValues);
  useEffect(() => {
    getProvinces()
  }, [])

  useUpdateEffect(() => {
    if (formValues?.province) {
      const provinceId = provinces.find(el => el.title == formValues.province)?.id
      if (provinceId) getDistricts(provinceId)
    }
  }, [formValues?.province])

  useUpdateEffect(() => {
    if (formValues?.province) {
      const provinceId = provinces.find(el => el.title == formValues.province)?.id
      if (provinceId) getDistrictsPrices(formValues?.id || null, provinceId)
    }
  }, [formValues?.province])

  const formSubmit = (e) => {
    e.preventDefault();
    setButtonLoading(true)
    console.log(deleting);
    handleSubmit((values) => {
      if (edit && !deleting) {
        if (formValues.formatType.value === 'home') {
          const requestBody = {
            details: districts.map(el => ({ district: el.id, price: formValues[el.id] }))
          }
          const provinceId = provinces.find((el) => el.title == formValues.province).id
          console.log(values.id);
          updateHomePrices(values.id, provinceId, requestBody)
            .then(() => {
              setButtonLoading(false)
            })
          return
        } else {
          editSpecialization(values).then((res) => {
            console.log("succes edit", res);
            // getAllSpecializations(10, current_page);
            setPagination(current_page);
            setButtonLoading(false)
          });
        }
        if (blob) {
          api.specializations
            .uploadFile(values.id, blob)
            .then((res) => {
              console.log("succes photo edit", res);
              // getAllSpecializations(10, current_page && current_page);
              setPagination(current_page);
              setButtonLoading(false)
            })
            .catch(() => console.error("ERROR_MESSAGE"));
          setBlob(null);
        }
        closeEditing();
      }
      if (edit && deleting) {
        console.log("deleting");
        deleteSpecializations(values.id).then((res) => {
          console.log(res);
          setFilters(filters.filter((el) => el.id !== values.id));
          // getAllSpecializations(10, current_page && current_page);
          setPagination(current_page);
          setButtonLoading(false)
        });
        closeEditing();
      }
      if (!edit) {
        addSpecialization(values).then(async ({ entity: { id }, entity }) => {
          setFilters([...filters, entity]);
          if (blob) {
            await api.specializations
              .uploadFile(id, blob)
              .then((res) => {
                console.log("succes add", res);
              })
              .catch(() => console.error("ERROR_MESSAGE"));
            setBlob(null);
          }
          // getAllSpecializations(10, current_page && current_page);
          setButtonLoading(false)
          setPagination(current_page);
        });
        closeEditing();
      }
    })();
  };

  return (
    <div className="wrappNewSpecializationForm">
      <div className={`formTitle ${edit ? "edit" : ""}`}>
        {`
      ${edit ? t("edit") : t("newSpecialty")} `}
        {edit && (
          <img
            src={close}
            alt="arrow-icon"
            onClick={() => {
              setEditingOff();
              resetInitialValues();
            }}
          />
        )}
      </div>
      <Form className="newSpecializationForm" onSubmit={formSubmit}>
        <div className="formContent">
          {edit && (
            <Field
              name="formatType"
              component={Toggle}
              options={[
                { value: "online", text: t("anamnez"), imgUrl: supportOnline },
                { value: "home", text: t("cardData"), imgUrl: supportHome },
              ]}
              imageMode
            />
          )}
          {(formValues?.formatType?.value === 'online' || !edit) && (
            <>
              <div className={`addingImg ${addingImg ? "show" : ""}`}>
                <div className="formTitle">
                  <img
                    src={arrowLeft}
                    alt="arrow-icon"
                    onClick={() => {
                      setAddingImg(false);
                      setEditingOff();
                    }}
                  />
                  {t("addImage")}
                </div>
                <div className="photoUploadFieldContainer">
                  <Field
                    name="photo"
                    // validate={[voidRequired]}
                    component={FileUploader}
                    previewTrashIcon={trash}
                    close={() => setAddingImg(false)}
                    editingImgUrl={
                      initialValues &&
                      initialValues.photo &&
                      initialValues.photo.cropImg
                    }
                    setBlob={setBlob}
                    setEditingOff={setEditingOff}
                    t={t}
                  />
                </div>
              </div>

              {formValues && formValues.photo && formValues.photo.cropImg ? (
                <div className="specAvatar">
                  <div className="flex alignCenter">
                    <img src={formValues.photo.cropImg} alt="avatar-icon" />
                    <div className="fileName">
                      {formValues && formValues.photo && formValues.photo.fileName}
                    </div>
                  </div>
                  <div
                    className="trashIcon"
                    onClick={() => {
                      reset();
                      setInitalValues({
                        ...formValues,
                        photo: null,
                      });
                    }}
                  >
                    <img src={trash} alt="" />
                  </div>
                </div>
              ) : (
                <Button
                  secondary
                  withIcon
                  icon={plus}
                  shevron={shevroRight}
                  type="button"
                  className="btn"
                  text={t("addButton")}
                  onClick={() => setAddingImg(true)}
                />
              )}

              {/* <Field
            name="title"
            component={FilterSelect}
            placeholder="Специализация"
            options={specializations}
            withSearch
            withIcon
            icon={plus}
            withPreview={formValues && formValues.title}
            previewIcon={docAvatar}
            initialValues={formValues && formValues.title}
          /> */}

              <Field
                validate={[voidRequired]}
                name="title"
                component={Input}
                placeholder={t("title")}
                allowSpaces
              />

              <Field
                name="priceOnline"
                type="number"
                validate={[voidRequired, notNegative]}
                component={Input}
                placeholder={t("priceOnline")}
                priceFormat
              />

              <Field
                name="description"
                placeholder={t("specializationDescription")}
                component={TextArea}
              />

              <Field
                validate={[voidRequired, notNegative]}
                name="priority"
                placeholder={t("priority")}
                type="number"
                component={Input}
              />

              <Field
                id={"viewMainScreen"}
                name="viewMainScreen"
                component={Checkbox}
                label={t("showOnMainScreen")}
              />

              <Field
                id={"viewCategoryList"}
                name="viewCategoryList"
                component={Checkbox}
                label={t("showOnDoctorsList")}
              />
            </>
          )}
          {formValues?.formatType?.value === 'home' && edit && (
            <>
              <Field
                name="province"
                options={provinces.map(el => el.title)}
                component={Select}
                placeholder={t("chooseCity")}
                autoComplete='off'
              />
              {loading && (
                <div className="loadingBox">
                  <Spinner small />
                </div>
              )}
              {formValues?.province && districts.length > 0 && !loading && (<>
                <div className="inputBox">
                  {districts.map(el => {
                    return (
                      <Field
                        name={el.id.toString()}
                        component={Input}
                        placeholder={t('cost')}
                        title={el.title}
                        priceFormat
                        type="number"
                        min={0}
                        max={100}
                        twoSectionMode
                        defaultValue={districtPrices.find(el => el.district.id === el.id)?.price || ''}
                      />
                    )
                  })}
                </div>
              </>)}
            </>
          )}
        </div>
        <div className={`formFooter ${edit ? "edit" : ""}`}>
          {!edit ? (
            <Button
              className="btn"
              disabled={invalid}
              type="submit"
              loading={buttonLoading}
              text={t("addSpecialty")}
            />
          ) : (
            <>
              <Button
                className="btn"
                // disabled={invalid && !edit}
                // disabled={checkIfDisabledSaving()}
                // disabled={buttonLoading}
                disabled={invalid}
                loading={buttonLoading}
                type="submit"
                text={t("saveButton")}
              />
              <Button
                className="btn"
                // disabled={buttonLoading}
                danger
                // disabled={invalid && !edit}
                type="submit"
                text={t("deleteButton")}
                onClick={() => setDeleting(true)}
                loading={buttonLoading}
              />
            </>
          )}
        </div>
      </Form>
    </div>
  );
};

const mapDispatchToProps = {
  setInitalValues,
  resetInitialValues,
  editSpecialization,
  addSpecialization,
  getAllSpecializations,
  deleteSpecializations,
  setPagination,
  getProvinces,
  getDistricts,
  getDistrictsPrices,
  updateHomePrices,
};


const mapStateToProps = (state) => ({
  formValues: getFormValues("specialization")(state),
  initialValues: state.formInitialValues,
  current_page: state.specialization && state.specialization.current_page,
  provinces: getProvincesSelector(state),
  districts: getDistrictsSelector(state),
  districtPrices: getPricesSelector(state),
  loading: getLoading(state),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "specialization", enableReinitialize: true })
);

export default enhance(NewSpecializationForm);
