import * as types from "./types";
import api from "../../api";
import moment from "moment";

export const getAllChatMessagesTemplates = () => (dispatch) =>
  api.chat.getChatMessagesTemplates().then((data) => {
    return dispatch({
      type: types.GET_CHAT_MESSAGES_TEMPLATES,
      ...data,
    });
  });

export const setIsNeedScrolling = (payload) => dispatch => {
  dispatch({type: types.SET_NEED_SCROLLING, payload})
}

export const getChatHistorySocket = (payload) => dispatch => {
  dispatch({type: types.GET_CHAT_HISTORY, payload})
}

export const leaveChatRoom = () => dispatch => 
  dispatch({type: types.LEAVE_ROOM})

export const getChatHistory =
  ({ consultationId, userId, page }) =>
    (dispatch) => {
      if (!consultationId && !userId) {
        return Promise.resolve()
      }
      if (consultationId) {
        return api.chat.getChatHistory(consultationId, page).then(({ data }) => {
          dispatch({
            type: types.SET_CHAT_HISTORY,
            historyChat: data,
          });
        });
      }
      if (userId) {
        return api.chat.getOperatorChatHistory(userId, page).then(({ data }) => {
          const list = data.items
            .filter((_) => _.status === 1 && _.isSentByUser)
            .map((_) => _._id);
          console.log("getOperatorChatHistory unread message", list, data);
          if (list.length) {
            dispatch({ type: types.READ_MESSAGES_LIST, list });
          }
          dispatch({
            type: types.SET_CHAT_HISTORY,
            historyChat: data,
          });
        });
      }
    };

export const startConsultation = (consultId) => (dispatch) => {
  return api.consultations.startConsultation(consultId);
};
export const clearHistoryChat = () => dispatch => {
  console.log("CLEARING CHAT");
  dispatch({ type: types.CLEAR_HISTORY_CHAT })
};

export const chatOperatorAudioMsgUpload =
  ({ userId, audiofile, audioDuration }) =>
    (dispatch) => {
    return api.chat
      .chatOperatorAudioMsgUpload({
        userId,
        file: audiofile,
      })
      .then(
        ({
          data: {
            entity: { file },
          },
        }) => {
          dispatch({
            type: types.SEND_CHAT_MESSAGE,
            payload: {
              type: "voice",
              userId,
              text: file,
              some: "sam some",
              audioDuration: audioDuration?.toString() || "",
            },
          });
        }
      );
  };

export const chatDoctorAudioMsgUpload =
  ({ id, audioFile, audioDuration }) =>
  (dispatch) => {
    return api.chat
      .chatAudioMsgUpload({
        id,
        file: audioFile,
      })
      .then(
        ({
          data: {
            entity: { file },
          },
        }) => {
          dispatch({
            type: types.SEND_CHAT_MESSAGE,
            payload: {
              type: "voice",
              appointmentId: id,
              text: file,
              some: "sam some",
              audioDuration: audioDuration?.toString() || "",
            },
          });
        }
      );
  };

export const chatAudioMsgUpload =
  ({ consultId, base64data, audioDuration }) =>
  (dispatch) => {
    return api.chat
      .chatAudioMsgUpload({
        id: consultId,
        file: base64data,
      })
      .then(
        ({
          data: {
            entity: { file },
          },
        }) => {
          dispatch({
            type: types.SEND_CHAT_MESSAGE,
            payload: {
              type: "voice",
              text: file,
              dateSent: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
              some: "sam some",
              audioDuration: audioDuration?.toString() || "",
            },
          });
        }
      );
  };

export const wsConnected = (options) => (dispatch) =>
  dispatch({ type: types.WS_CONNECT, payload: options });
export const wsDisconnected = () => (dispatch) =>
  dispatch({ type: types.WS_DISCONNECT });
export const connectCallWS = (options) => (dispatch) =>
  dispatch({
    type: types.WS_CALL_CONNECT,
    payload: { query: options },
  });
export const disconnectCallWS = () => (dispatch) =>
  dispatch({
    type: types.WS_CALL_DISCONNECT,
  });

export const makeChatCall =
  ({ isVideo, doctor, docSpecialty, consultId, userVideo, partnerVideo, userAudio, patientId }) =>
  (dispatch) =>
    dispatch({
      type: types.MAKE_CHAT_CALL,
      payload: {
        patientId,
        isVideo,
        doctor,
        userVideo,
        partnerVideo,
        userAudio,
        consultId,
        title: docSpecialty.title,
      },
    });
export const sendPushNotification =
  ({ isVideo, doctor, docSpecialty, patientId, consultId }) =>
  (dispatch) => {
    api.chat.deviceCall({
      appointmentId: consultId,
      data: {
        type: isVideo ? 2 : 1,
        text: isVideo ? "Начало видео звонка" : "Начало аудио звонка",
        userId: patientId,
        doctorId: `${doctor.doctor.id}`,
        doctor_name: doctor.fullName,
        doctorSpecialtyId: `${docSpecialty.id}`,
      },
    });
  };
export const mutedAudioChatCall = () => (dispatch) =>
  dispatch({
    type: types.MUTE_AUDIO_CHAT_CALL,
  });
export const mutedVideoChatCall = () => (dispatch) =>
  dispatch({
    type: types.MUTE_VIDEO_CHAT_CALL,
  });
export const stopChatCall = () => (dispatch) =>
  dispatch({ type: types.STOP_CHAT_CALL });
export const startRecordingAudioMessage = () => ({
  type: types.START_MEDIA_RECORDER,
});
export const stopRecordingAudioMessage = () => ({
  type: types.STOP_MEDIA_RECORDER,
});
export const sendAudioMessage = (options) => ({
  type: types.SEND_MEDIA_RECORDER,
  payload: options,
});
export const sendChatMessage = (options) => (dispatch) =>
  dispatch({
    type: types.SEND_CHAT_MESSAGE,
    payload: options,
  });
export const setRejected = (isRejected) => (dispatch) =>
  dispatch({
    type: types.SET_REJECTED,
    payload: isRejected
  });
export const sendRejectMessage = (options) => (dispatch) =>
  dispatch({
    type: types.SEND_REJECT_MESSAGE,
  });

export const deleteChatMessage = (role, messageId) => (dispatch) => {
  console.log("body", {role, messageId});
  dispatch({
    type: types.DELETE_CHAT_MESSAGE,
    payload: { messageId, role }
  })
}

export const editChatMessage = ({ messageId, message, role }) => (dispatch) => {
  dispatch({
    type: types.EDIT_CHAT_MESSAGE,
    payload: { messageId, role, message },
  })
}

export const readMessagesList = (list) => (dispatch) =>
  dispatch({ type: types.READ_MESSAGES_LIST, list });
