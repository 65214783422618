import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";
import {
  getMessageList,
  getBothInChat,
  getIsNeedScrolling,
  getItemsPerPage,
  getTotalItemCount,
  getCurrentPage,
  getIsTheEndPage,
} from "../../../../redux/chat/selectors";

import {
  getChatHistory,
  deleteChatMessage,
  clearHistoryChat,
} from "../../../../redux/chat/actions";
import Spinner from "../../ui/Spinner/Spinner";
import { MessageComponent } from "./";

const MESSAGE_LIMIT = 20;

const MessagesList = ({
  role,
  t,
  userId,
  setMessageValue,
  bothInChat,
  setMessageEdit,
  consultId,
  historyChat,
  status,
  videoCall,
  totalItemCount,
  itemsPerPage,
  getChatHistory,
  deleteChatMessage,
  clearHistoryChat,
  isNeedScrolling,
  updateChatHistory,
  currentPage,
  isTheEndPage,
}) => {
  const messageListRef = useRef(null);
  const prevScrollPosition = useRef(null);

  const calculateScrollPosition = () => {
    const { scrollHeight, clientHeight } = messageListRef.current
    const currentPosition = currentPage === 0
      ? 0
      : scrollHeight - clientHeight
    const delta = currentPosition - prevScrollPosition.current
    const scrollToPosition = delta < 0 ? prevScrollPosition.current : delta
    return scrollToPosition
  }

  const scrollHandler = (e) => {
    if (totalItemCount <= currentPage * itemsPerPage) {
      return;
    }

    const { scrollTop, clientHeight, scrollHeight } = e.target;
    const isTheEnd = scrollTop === 0;
    if (isTheEnd && !isTheEndPage) {
      prevScrollPosition.current = scrollHeight - clientHeight;
      updateChatHistory(currentPage + 1);
    }
  };

  useEffect(() => {
    updateChatHistory();
    return () => clearHistoryChat();
  }, [userId]);

  useEffect(() => {
    if (currentPage === 0) {
      messageListRef.current.scrollTo({
        top: 99999,
      });
      return;
    }
    messageListRef.current.scrollTo({
      top: calculateScrollPosition(),
    });
  }, [historyChat]);

  useUpdateEffect(() => {
    messageListRef.current &&
      messageListRef.current.scrollTo({
        top: 999999,
      });
  }, [isNeedScrolling]);

  return (
    <div
      ref={messageListRef}
      className={`${videoCall ? "messagesArea video" : "messagesArea"} ${status}`}
      id="messagesArea"
      onScroll={scrollHandler}
    >
      {historyChat.map((group, i) => (
        <div className="one_day_messages" key={`${group.date}${i}`}>
          <div className="chatDate">{group.date}</div>
          {group.list.map((item, i) => (
            <MessageComponent
              key={i}
              item={item}
              bothInChat={bothInChat}
              t={t}
              role={role}
              deleteChatMessage={deleteChatMessage}
              setMessageValue={setMessageValue}
              chooseMessageId={setMessageEdit}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  historyChat: getMessageList(state),
  totalItemCount: getTotalItemCount(state),
  itemsPerPage: getItemsPerPage(state),
  bothInChat: getBothInChat(state),
  isNeedScrolling: getIsNeedScrolling(state),
  currentPage: getCurrentPage(state),
  isTheEndPage: getIsTheEndPage(state),
});

export default withRouter(
  connect(mapStateToProps, {
    getChatHistory,
    deleteChatMessage,
    clearHistoryChat,
  })(MessagesList)
);
