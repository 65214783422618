import { GET_NOTIFICATIONS_LIST } from "../../notifications/types";
import * as types from "../types";

const messagesStatuses = {
  readed: 2,
  sent: 1,
}

export const socketHandlesList = {
  operator: {
    onAuth: (dispatch) => ({ user, status = 0 }) =>
      dispatch({ type: types.IS_AURTH, status, user }),
    
    onDeleteMessage: (dispatch) => ({ messageId }) =>
      dispatch({ type: types.REMOVE_CHAT_MESSAGE, messageId }),
    
    onEditMessage: (dispatch) => ({ messageId, message }) =>
      dispatch({ type: types.CHANGE_CHAT_MESSAGE, messageId, message }),
    
    onGetHistoryAppointment: (dispatch) => ({ messages }) =>
      dispatch({ type: types.SET_CHAT_HISTORY, messages }),
    
    onGetHistoryWithOperator: (dispatch) => ({ messages, current_page }) => {
      const isTheEndPage = messages.length === 0

      const unreadedMessages = messages
        .filter(msg => (msg.status === messagesStatuses.sent) && msg.isSentByUser)
        .map(msg => msg._id);
      
      if (unreadedMessages.length) {
        dispatch({type: types.READ_MESSAGES_LIST, list: unreadedMessages, messageType: "patientToOperator", userId: messages[0].userId})
      }
      dispatch({
        type: types.SET_CHAT_HISTORY,
        historyChat: messages,
        current_page,
        isTheEndPage,
      })
    },

    onGetNotifications: (dispatch) => (data) => {
      dispatch({
        type: GET_NOTIFICATIONS_LIST,
        list: data.notifications,
        total: data.total_item_count,
        currentPage: data.current_page || 1,
        pageCount: data.page_count || 1,
        fromButtons: data.usingButtons,
      });
    },
    
    onMessage: (dispatch) => ({ message, messageType }) => {
      dispatch({ type: types.SET_NEW_MESSAGE, message })
      if (message.isSentByUser) {
        dispatch({ type: types.READ_MESSAGES_LIST, list: [message._id], messageType });
      }
    },
    
    isReadMessageIds: (dispatch) => ({ idsList }) =>
      dispatch({ type: types.IS_READ_MESSAGES_LIST, idsList }),
    
    onJoinRoom: (dispatch) => ({ userId, totalMembers }) =>
      dispatch({
        type: types.SET_TOTAL_CHAT_MEMBERS,
        countMembersInRoom: totalMembers,
      }),
    
    onChatRoomLeave: (dispatch) => ({ userId, totalMembers }) =>
      dispatch({
        type: types.SET_TOTAL_CHAT_MEMBERS,
        countMembersInRoom: totalMembers,
      }),
  },

  doctor: {
    onAuth: (dispatch) => ({ user, status = 0, socketId = null }) =>
      dispatch({ type: types.IS_AURTH, status, user }),
    
    onCallMessage: (dispatch, callTools) => ({ type, candidate, answer, ...data }) => {
      switch (type) {
        case "answer":
          callTools.catchAnswer({ answer });
          break;
        case "candidate":
          callTools.onSocketCandidate(candidate);
          break;
        case "ready":
          callTools.makeCall({ isVideo: true });
          break;
        case "reject":
          callTools.stopCall({ endedByMe: false, rejected: true });
          break;
        case "ended":
          callTools.stopCall({ endedByMe: false });
          break;
        default: return
      }
    },
    onDeleteMessage: (dispatch) => ({ messageId }) =>
      dispatch({ type: types.REMOVE_CHAT_MESSAGE, messageId }),
    
    onEditMessage: (dispatch) => ({ messageId, message }) =>
      dispatch({ type: types.CHANGE_CHAT_MESSAGE, messageId, message }),
    
    onGetHistoryWithAttendingDoctor: (dispatch) => ({ messages, current_page }) => {
      const isTheEndPage = messages.length === 0

      const unreadedMessages = messages
        .filter(msg => (msg.status === messagesStatuses.sent) && msg.isSentByUser)
        .map(msg => msg._id);
      
      if (unreadedMessages.length) {
        dispatch({type: types.READ_MESSAGES_LIST, list: unreadedMessages, messageType: "patientToDoctor", userId: messages[0].userId})
      }
      dispatch({
        type: types.SET_CHAT_HISTORY,
        historyChat: messages,
        current_page,
        isTheEndPage,
      })
    },

    onGetHistoryAppointment: (dispatch) => ({ messages, current_page }) => {
      const isTheEndPage = messages.length === 0

      const unreadedMessages = messages
        .filter(msg => (msg.status === messagesStatuses.sent) && msg.isSentByUser)
        .map(msg => msg._id);
      
      if (unreadedMessages.length) {
        dispatch({type: types.READ_MESSAGES_LIST, list: unreadedMessages, messageType: "patientToDoctor", userId: messages[0].userId})
      }
      dispatch({
        type: types.SET_CHAT_HISTORY,
        historyChat: messages,
        current_page,
        isTheEndPage,
      })
    },

    onMessage: (dispatch) => ({ message, messageType }) => {
      dispatch({ type: types.SET_NEW_MESSAGE, message })
      if (message.isSentByUser) {
        dispatch({ type: types.READ_MESSAGES_LIST, list: [message._id], messageType });
      }
    },
    
    isReadMessageIds: (dispatch) => ({ idsList }) =>
      dispatch({ type: types.IS_READ_MESSAGES_LIST, idsList }),
    
    onJoinCall: ({ dispatch }) => ({ user }) => {},
    onLeaveCall: ({ user }) => { },
    
    onJoinRoom: (dispatch) => ({ userId, totalMembers }) =>
      dispatch({
        type: types.SET_TOTAL_CHAT_MEMBERS,
        countMembersInRoom: totalMembers,
      }),
    
    onChatRoomLeave: (dispatch) => ({ userId, totalMembers }) =>
      dispatch({
        type: types.SET_TOTAL_CHAT_MEMBERS,
        countMembersInRoom: totalMembers,
      }),
  },
};
