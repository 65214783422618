import { sendChatMessage, setRejected } from "../actions";
import { getIsAudioCall, getIsVideoCall } from "../selectors";
import * as ACTION_TYPES from "../types"

const configuration = {
  iceServers: [
    { urls: "stun:relay1.gooddoc.fun:80" },

    {
      urls: "turn:relay1.gooddoc.fun:80?transport=udp",
      username: "domus",
      credential: "sPy80Hb7o(",
    },
    {
      urls: "turn:relay1.gooddoc.fun:80?transport=tcp",
      username: "domus",
      credential: "sPy80Hb7o(",
    },
  ],
}


export function webrtcTools({dispatch, getState, socket, sendCallMessage, sendRejectMessage, sendEndCallMessage}) {
  let peerConnection = null;
  let iceCandidates = [];
  let myStream = null;
  let remoteStream = new MediaStream()
  let isReadyStatus = false; // we need to delete this
  let reserveOptions = {};
  let isVideoCall = false;
  let isMessageConnect = false;

  const _createPeerConnect = () => {
    const peer = new RTCPeerConnection(configuration)
    peer.addEventListener("icecandidate", _onIceCandidate)
    // peer.addEventListener("track", onTrack)
    peer.addEventListener("connectionstatechange", _onConnectionStateChange)
    return peer
  }

  const _closePeer = () => {
    peerConnection && peerConnection.close();
    peerConnection = null;
    iceCandidates = []
    reserveOptions = {};
    myStream = null
    isReadyStatus = false
    remoteStream = new MediaStream()
  }

  const _onIceCandidate = (rtcp) => {
    if (rtcp.candidate) {
      sendCallMessage({
        type: "candidate",
        candidate: rtcp.candidate,
      })
    }
  }

  // const sendCallMessage = (data) => {
  //   socket.emit("callMessage", {
  //     appointmentId: appointmentId,
  //     message: {
  //       ...data,
  //     } 
  //   })
  // }

  const onSocketCandidate = (cand) => {
    const candidate = new RTCIceCandidate(cand);
    iceCandidates.push(candidate)
  }

  const _setIceCandidate = () => {
    iceCandidates.forEach((candidate) => {
      peerConnection.addIceCandidate(candidate)
    })
  }

  const _onConnectionStateChange = (event) => {
    if (event.currentTarget.connectionState === "failed") {
      isReadyStatus = false;
      peerConnection.removeEventListener(
        "connectionstatechange",
        _onConnectionStateChange
      );
      _closePeer()
      // peerConnection.close();
      // peerConnection = null;
      // createCall(reserveOptions);
    }
  }

  const createTrackListener = ({ isVideo, userVideo, userAudio }) => {
    peerConnection.addEventListener("track", (event) => {
      console.log("GETTING TRACK", event);
      remoteStream.addTrack(event.track, remoteStream);
      try {
        if (isVideo) {
          userVideo.current.srcObject = remoteStream;
        } else {
          userAudio.current.srcObject = remoteStream;
        }
      } catch (e) {
        console.log("<<<<<<<<<TRACK _______ E R O R R>>>>>>>>", e);
      }
    })
  }

  const createCall = async ({
    partnerVideo,
    consultId,
    patientId,
    doctor,
    isVideo,
    title,
    userVideo,
    userAudio,
  }) => {
    isVideoCall = isVideo
    console.log("createPeerconnect");
    reserveOptions = {
      partnerVideo,
      consultId,
      doctor,
      isVideo,
      title,
      userVideo,
      userAudio,
      patientId,
    };
    peerConnection = _createPeerConnect();
    const stream = await navigator.mediaDevices.getUserMedia({ video: isVideo, audio: true })
    myStream = stream;
    // partnerVideo.current.srcObject = stream;
    stream.getTracks().forEach((track) => {
      peerConnection.addTrack(track, stream);
    })
    createTrackListener({ isVideo, userVideo, userAudio })
    console.log("shdg", doctor);
    sendCallMessage({
      type: "call",
      user_id: patientId,
      customParameters: {
        call_type: isVideo ? "video" : "audio",
        doctor_id: `${doctor.doctor.id}`,
        doctor_name: doctor.fullName,
        doctor_photo: doctor.photoUrl,
        doctor_specialty: title,
        // doctor_specialty_id: doctor.specialty.id,
      },
    })
  }

  const makeCall = async () => {
    const offer = await peerConnection.createOffer({
      iceRestart: true,
      offerToReceiveAudio: true,
      offerToReceiveVideo: isVideoCall,
    });

    await peerConnection.setLocalDescription(offer);
    sendCallMessage({
      type: "offer",
      offer: offer.sdp,
    })
  };

  const catchAnswer = async ({ answer }) => {
    if (isVideoCall) {
      dispatch({ type: ACTION_TYPES.START_VIDEO_CALL });
    } else {
      dispatch({ type: ACTION_TYPES.START_AUDIO_CALL });
    }
    const remoteDesc = new RTCSessionDescription({
      type: "answer",
      sdp: answer || answer.nameValuePairs.answer,
    });
    await peerConnection.setRemoteDescription(remoteDesc);
    _setIceCandidate()
  };

  const stopCall = ({endedByMe, rejected = false}) => {
    endStream()
    _closePeer()
    const isAudioCall = getIsAudioCall(getState());
    const isVideoCall = getIsVideoCall(getState());
    if (endedByMe) {
      sendCallMessage({
        type: "ended",
        customParameters: {}
      })
    }
    if (endedByMe && !isVideoCall && !isAudioCall) {
      console.log("SEND MISSED CALL");
      sendRejectMessage(true)
    }
    if (!endedByMe && rejected) {
      dispatch(setRejected(true));
      sendRejectMessage(false);
    }
    if (isAudioCall || isVideoCall) {
      console.log("SEND END CALL MESSAGE");
      sendEndCallMessage()
    }
    dispatch({ type: ACTION_TYPES.RESET_CALL });
  };

  const endStream = () => {
    myStream && myStream
      .getTracks().forEach((track) => {
        track.stop();
        myStream.removeTrack(track);
      });
  }

  const muteVideo = () => {
    myStream && myStream
      .getVideoTracks()
      .forEach((track) => (track.enabled = !track.enabled));
  };

  const muteAudio = () => {
    myStream && myStream
      .getAudioTracks()
      .forEach((track) => (track.enabled = !track.enabled));
  };

  return {
    createCall,
    muteVideo,
    muteAudio,
    makeCall,
    onSocketCandidate,
    catchAnswer,
    stopCall,
  }
}