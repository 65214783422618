import moment from "moment";
import i18n from "../i18n";

export const requiredLogin = (value) =>
  (value && typeof value === "string" && !value.match(/^\s+$/)) ||
  (value && typeof value !== "string")
    ? undefined
    : i18n.t("requiredLoginValidate");

export const absentLogin = (values) => {
  console.log(values);
  return "";
};

// let r = /\d{2}\/\d{2}\/\d{4}/gmi;
// console.log(r.test(value));
export const dd_mm_yyyy = (value) =>
  value === "" ||
  value === null ||
  value === undefined ||
  (value && /\d{2}\/\d{2}\/\d{4}/gim.test(value))
    ? undefined
    : i18n.t("dd_mm_yyyyValidate");

export const dateFormatValidation = (value) => {
  return moment(value, 'DD/MM/YYYY').isValid()
    ? undefined
    : i18n.t("dd_mm_yyyyValidate")
}

export const inPast = (value) => {
  let today = moment().format("YYYY-MM-DD");
  let vMoment;
  if (value.length > 9) {
    vMoment = value.split(" ")[0];
  }
  // console.log(today, vMoment, moment(vMoment).isBefore(today));
  return (
    value === "" ||
    value === null ||
    value === undefined ||
    (value && moment(vMoment).isBefore(today, "day")
      ? undefined
      : "birthDateInFuture")
  );
};
export const nameFormat = (value) =>
  value === "" ||
  value === null ||
  value === undefined ||
  (value && /^[А-ЯA-Za-zа-яёЁЇїІіЄєҐґ]{1,}$/.test(value))
    ? undefined
    : i18n.t("nameFormatValidate");

export const phoneFormat = (value) =>
  value === "" ||
  value === null ||
  value === undefined ||
  (value && /^\+380\d{3}\d{2}\d{2}\d{2}$/.test(value))
    ? undefined
    : i18n.t("phoneFormatValidate");

export const durationTimeOnline = (value) => {
  const errorText = i18n.t("durationTimeInMinValidate")
  if (value) {
    return ['20', '30', '60'].includes(String(value)) ? undefined : errorText
  }
  return undefined
}

export const durationTimeAtHome = (value) => {
  const errorText = i18n.t("durationTimeInMinValidateHome")
  if (value) {
    return ['90', '180'].includes(String(value)) ? undefined : errorText
  }
  return undefined
}

export const normalizeDigits = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, "");
  if (!previousValue || value.length > previousValue.length) {
    if (onlyNums.length === 4 || onlyNums.length <= 4) {
      return onlyNums;
    }
  }
  return onlyNums.slice(0, 4);
};

export const timeInMin = (value) =>
  value === "" ||
  value === null ||
  value === undefined ||
  (value && /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value))
    ? undefined
    : i18n.t("timeInMinValidate");

export const email = (value) =>
  value === "" ||
  value === null ||
  value === undefined ||
  (value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))
    ? undefined
    : i18n.t("emailValidate");

export const requiredPassword = (value) =>
  (value && typeof value === "string" && !value.match(/^\s+$/)) ||
  (value && typeof value !== "string")
    ? undefined
    : i18n.t("requiredPasswordValidate");

export const required = (value) =>
  (value && typeof value === "string" && !value.match(/^\s+$/)) ||
  (value && typeof value !== "string")
    ? undefined
    : i18n.t("requiredValidate");

export const requiredIsNotEmpty = (value) => value
    ? undefined
    : i18n.t("requiredValidate");

export const voidRequired = (value) =>
  (value && typeof value === "string" && !value.match(/^\s+$/)) ||
  (value && typeof value !== "string")
    ? undefined
    : " ";
export const nameValidation = (value) => {
  if (!value) return true;
  return /^[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`][a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]+[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]?$/.test(value) ? undefined : true;
};
export const experienceValidation = (value, age) => {
  if (!value) return true;
  return +value + 18 > age ? true : undefined;
};

export const notNegative = (value) =>
  value === "" ||
  value === null ||
  value === undefined ||
  !(value && /-\d{0,}/gm.test(value))
    ? undefined
    : i18n.t("notNegativeValidate");

export const maskTime = (e, input) => {
  if (input.value && input.value.length === 2 && e.keyCode !== 8) {
    input.onChange(`${input.value}:`);
  } else if (input.value && input.value.length === 2 && e.keyCode === 8) {
    input.onChange(input.value.substr(0, 1));
  }
  if (input.value && input.value.length >= 4 && e.keyCode !== 8) {
    input.onChange(input.value.substr(0, 5));
  }
};

export const cirillicResist = (e, input) => {
  if (/\w/.test(e.target.value)) {
    // console.log("ok");
    input.onChange(input.value);
  } else {
    // console.log("neok");
    input.onChange(input.value.substr(input.value.length, 1));
  }
};

export const formatBirthDate = (birtdate) => {
  const newDate = moment(birtdate, "DD/MM/YYYY").format("YYYY-MM-DD");
  return newDate;
};
