import moment from "moment";
import io from "socket.io-client";
import * as ACTION_TYPES from "../types";
import { SIGNALING_SERVER } from "../../../api/index";
import { socketHandlesList } from "./handlers";
import { webrtcTools } from "./webrtc";
import { setRejected } from "../actions";
import i18n from "../../../i18n";
import { REQUEST_NOTIFICATION_LIST } from "../../notifications/types";
const CHAT_URL = `${SIGNALING_SERVER}:${8085}`;
// const CHAT_URL = `http://localhost:8085`;

const chatStatuses = {
  DISCONNECTED: "disconnected",
  CONNECTING: "connecting",
  CONNECTED: "connected",
}

const rolesMap = {
  "doctor": "patientToDoctor",
  "operator": "patientToOperator",
  "personalDoctor": "attendingToDoctor",
}

const socketMiddleware = () => {
  let call = {
    mutedAudioChatCall: () => { },
    mutedVideoChatCall: () => { },
    stopCall: () => { },
  };
  let cbListForAuth = [];
  let callTools = null;
  let isAuthenticated = false
  let currentAppointmentId = null;
  let socket = null;

  const setConnectionStatus = (dispatch, status) => {
    dispatch({
      type: ACTION_TYPES.CHANGE_CONNECT_STATUS_CHAT,
      payload: { status },
    });
  }

  const sendCallMessage = (data) => {
    socket.emit("callMessage", {
      appointmentId: currentAppointmentId,
      message: {
        ...data,
      }
    })
  }

  const sendRejectMessage = (me) => {
    const messageBody = {
      messageType: "patientToDoctor",
      appointmentId: currentAppointmentId,
      type: "message",
      message: me ? i18n.t("missedCall") : i18n.t("canceledCall"),
      dateSent: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      isSentByUser: !me,
      status: 1,
      customParameters: {
        some: "param",
      },
    };
    socket.emit("message", messageBody)
  };

  const sendEndCallMessage = () => {
    const messageBody = {
      messageType: "patientToDoctor",
      appointmentId: currentAppointmentId,
      type: "message",
      message: "Завершенный звонок",
      dateSent: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      isSentByUser: false,
      status: 1,
      customParameters: {
        some: "param",
      },
    };
    socket.emit("message", messageBody)
  };

  const sendChatMessage = ({ type, appointmentId, cardId, userId, text, ...options }) => {
    let messageType = "patientToOperator" 
    if (appointmentId) messageType = "patientToDoctor"
    if (cardId) messageType = "attendingToDoctor"
    
    socket.emit("message", {
      messageType: messageType,
      type,
      userId,
      appointmentId,
      cardId,
      status: 1,
      message: text,
      isSentByUser: false,
      dateSent: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      customParameters: {
        ...options,
      },
    });
  }

  // the middleware part of this function
  return ({ dispatch, getState }) => (next) => (action) => {
    switch (action.type) {
      case ACTION_TYPES.WS_CONNECT:
        if (socket !== null) {
          socket.close();
        }
        setConnectionStatus(dispatch, chatStatuses.CONNECTING)

        socket = io.connect(CHAT_URL, {
          "reconnection delay": 0,
          "reopen delay": 0,
          "force new connection": true,
          transports: ["websocket"],
        });

        socket.on("connect", () => {
          callTools = webrtcTools({dispatch, getState, socket, sendCallMessage, sendRejectMessage, sendEndCallMessage})
          socket.emit("auth", { token: localStorage.getItem("token") });
          setConnectionStatus(dispatch, chatStatuses.CONNECTED)
        });

        socket.on("disconnect", () => setConnectionStatus(dispatch, chatStatuses.DISCONNECTED))
        socket.on("connect_error", () => setConnectionStatus(dispatch, chatStatuses.DISCONNECTED))

        socket.on("onAuth", ({ user }) => {
          isAuthenticated = true
          if (user.role.includes("OPERATOR")) {
            Object.keys(socketHandlesList["operator"]).forEach((key) => {
              socket.on([key], socketHandlesList["operator"][key](dispatch));
            });
            // socket.emit("getNotifications", {
            //   skip: 0,
            //   limit: 20,
            // })
          }
          if (user.role.includes("DOCTOR")) {
            Object.keys(socketHandlesList["doctor"]).forEach((key) => {
              if (key === "onCallMessage") {
                socket.on([key], socketHandlesList["doctor"][key](dispatch, callTools));
              } else {
                socket.on([key], socketHandlesList["doctor"][key](dispatch));
              }
            });
          }
          cbListForAuth.forEach((cb) => cb());
          cbListForAuth = [];
        });
        break;
        
      case ACTION_TYPES.GET_CHAT_HISTORY:
        const { consultationId, userId, page, startDate, endDate, role, isAttendingDoctor } = action.payload;
        currentAppointmentId = consultationId
        if (role === "doctor") {
          console.log("CONNECTED TO CALL TOOLS");
        }
        const typeRoom = rolesMap[role]
        
        function getHistory() {
          if (page === 0) {
            if (role === "operator") socket.emit("joinRoom", { typeRoom, userId });
            if (role === "personalDoctor") socket.emit("joinAttendingDoctorRoom", {typeRoom, cardId: userId})
            if (role === "doctor") socket.emit("joinAppointmentRoom", { typeRoom, appointmentId: consultationId});
          }
          if (role === "doctor") {
            socket.emit("getHistoryAppointment", {
              appointmentId: consultationId,
              skip: page,
            });
          }

          if (role === "personalDoctor") {
            socket.emit("getHistoryWithAttendingDoctor", {
              cardId: userId,
              skip: page,
            });
          }

          if (role === "operator") {
            socket.emit("getHistoryWithOperator", {
              userId,
              skip: page,
              startDate,
              endDate,
            });
          }
        }
        if (socket && isAuthenticated) {
          getHistory();
        } else {
          cbListForAuth.push(getHistory);
        }
        break;

      case ACTION_TYPES.JOIN_ROOM:
        socket.emit("joinRoom");
        break;
      
      case REQUEST_NOTIFICATION_LIST:
        function getNotifications() {
          socket.emit("getNotifications", {
            limit: 10,
            page: action.page,
            usingButtons: action.usingButtons,
          })
        }
        if (socket && isAuthenticated) {
          getNotifications()
        } else {
          cbListForAuth.push(getNotifications)
        }
        
        break;
      
      case ACTION_TYPES.DELETE_CHAT_MESSAGE: {
        console.log("payload", action.payload);
        const { messageId, role } = action.payload
        const messageType = rolesMap[role];
        socket.emit("deleteMessage", { messageId, messageType });
        break;
      }
        

      case ACTION_TYPES.EDIT_CHAT_MESSAGE: {
        const { messageId, role, message } = action.payload
        const messageType = rolesMap[role];
        socket.emit("editMessage", { messageId, messageType, message });
        break;
      }
        
      case ACTION_TYPES.READ_MESSAGES_LIST:
        socket.emit("readMessagesList", { idsList: action.list, messageType: action.messageType });
        break;
      case ACTION_TYPES.WS_DISCONNECT:
        console.log("DISCONNECT");
        if (socket) {
          socket.emit("chatRoomLeave");
          socket.close();
        }
        isAuthenticated = false
        socket = null;
        break;
      
      case ACTION_TYPES.LEAVE_ROOM:
        socket && socket.emit("chatRoomLeave");
        break;

      case ACTION_TYPES.WS_CONNECT_TO_ROOM:
        function joinRoom() {
          socket.emit("joinRoom", action.payload);
        }
        if (!socket) {
          cbListForAuth.push(joinRoom);
        } else {
          joinRoom();
        }
        break;
      case ACTION_TYPES.MAKE_CHAT_CALL:
        dispatch(setRejected(false));
        callTools.createCall(action.payload)
        break;

      case ACTION_TYPES.SEND_CHAT_MESSAGE:
        console.log(action.payload);
        sendChatMessage(action.payload);
        break;

      case ACTION_TYPES.MUTE_AUDIO_CHAT_CALL:
        callTools.muteAudio()
        break;

      case ACTION_TYPES.MUTE_VIDEO_CHAT_CALL:
        callTools.muteVideo()
        break;

      case ACTION_TYPES.STOP_CHAT_CALL:
        callTools.stopCall({ endedByMe: true });
        break;

      case ACTION_TYPES.WS_DISCONNECT_FROM_ROOM:
        callTools.stopCall({ endedByMe: true });
        break;

      default:
        return next(action);
    }
  };
};

export default socketMiddleware();
