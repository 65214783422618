import * as types from "./types";
import api from "../../api";

export const toggleModal =
  (openModal = false) =>
  (dispatch) => {
    if (typeof openModal === "boolean") {
      return dispatch({
        type: types.TOGGLE_MODAL,
        openModal,
      });
    }
  };

export const addItemToList = (item) => (dispatch) => {
  if (item) {
    return dispatch({
      type: types.ADD_ITEM_TO_LIST,
      item,
    });
  }
};

const list = [
  {
    id: 3,
    title: "Заявка через приложение",
    date: new Date(2020, 9, 2, 14, 43, 15, 0).getTime(),
  },
  {
    id: 2,
    title: "Клиент не подключился к консультации",
    date: new Date(2020, 9, 2, 10, 35, 15, 0).getTime(),
  },
  {
    id: 1,
    title: "Врач не вышел на смену",
    date: new Date(2020, 9, 1, 11, 35, 15, 0).getTime(),
  },
];

export const getNotificationsList = (page, _, usingButtons = false) => dispatch => {
  dispatch({type: types.REQUEST_NOTIFICATION_LIST, page, usingButtons})
}

export const getNotificationsListOld = (page = 1, readImmediately = false, fromButtons = false) => async (dispatch) => {
  if (!readImmediately) {
    dispatch({ type: types.GET_REQUEST })
  }
  const res = await api.notification.getNotificationsList(page)
  if (readImmediately && res?.data?.items) {
    dispatch(readNotification(res.data.items[0].id))
    return
  }
  return dispatch({
    type: types.GET_NOTIFICATIONS_LIST,
    list: res && res.data && res.data.items,
    total: res && res.data && res.data.total_item_count,
    currentPage: res?.data?.current_page || 1,
    pageCount: res?.data?.page_count || 1,
    fromButtons: fromButtons,
  });
};

export const readNotification = (consultationId) => (dispatch) => {
  api.notification
    .readNotification(consultationId)
    .then((res) => res.entity.status === 1 && getNotificationsList()(dispatch));
};
