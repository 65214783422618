import React, { useCallback, useRef } from "react";
import { connect } from "react-redux";
import moment from "moment";
import api from "../../../api";
import { useHistory } from "react-router-dom";
import { clearPatient } from "../../../redux/patients/actions";
import { getNotificationsList, readNotification } from "../../../redux/notifications/actions";
import "./NotificationsModal.scss";
import ShevronRight from "../../../style/icons/ShevronRight/shevron.svg";
import ShevronRightBlue from "../../../style/icons/ShevronRight/shevronBlue.svg";
import white_bell from "../../../style/icons/Bell/white-bell-in-blue.svg";
import { useDispatch } from "react-redux";
import Spinner from "../ui/Spinner/Spinner";
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { useTranslation } from "react-i18next";

const NotificationItem = ({
  item,
  closeModal,
  clearPatient,
  readNotification,
  getNotificationsList,
}) => {
  const history = useHistory();
  const { t } = useTranslation()
  
  const handleClick = async () => {
    await readNotification(item.id);
    clearPatient();
    if (item.user && item.user.patient) {
      api.patients.getPatientCards(item.user.patient.id).then((response) => {
        const main_card = response.data.items.find(
          (item) => item.relationShip && item.relationShip.id === 1
        );
        if (main_card) {
          // debugger;
          history.push(
            `/home/operator/client/${item.user.patient.id}/${main_card.id}?user=${item.user.id}&consultId=`,
            {from: `${history.location.pathname}${history.location.search}`}
          );
          closeModal();
        } else if (response.data.items.length) {
          // debugger;
          history.push(
            `/home/operator/client/${item.user.patient.id}/${response.data.items[0].id}?user=${item.user.id}&consultId=`,
            {from: `${history.location.pathname}${history.location.search}`}
          );
          closeModal();
        } else {
          // debugger;
          history.push(
            `/home/operator/client/${item.user.patient.id}/${item.user.id}?user=${item.user.id}&consultId=`,
            {from: `${history.location.pathname}${history.location.search}`}
          );
        }
      });
    }
    getNotificationsList()
  };

  const itemTime = moment(item.created).format("HH:mm")
  const messageText = `${t("newMessage")} ${t("fromTo")} ${item.User.phone} ${t("in")} ${itemTime}`
  const itemFullDate = moment(item.created).format("HH:mm (DD.MM.YYYY)")

  const isNotReaded = item.status === 1

  return (
    <div
      className={`notification-item ${!isNotReaded ? "blue" : ""}`}
      onClick={handleClick}
    >
      <div className="type">
        {/* {item.status === 0 && <img src={white_bell} alt="" />} */}
      </div>
      <div className="notification-item-content">
        <div className="text">
          <p style={isNotReaded ? {} : {color: "#4b9bf5"}} className="title">{messageText}</p>
          <p className="date">{itemFullDate}</p>
          <p className="id">#{item.id}</p>
        </div>
        {isNotReaded ? (
          <img src={ ShevronRight } alt="arrow-right" />
        ) : (
          <img style={{opacity: '1'}} src={ShevronRightBlue} alt="arrow-right" />
        )}
      </div>
    </div>
  );
};

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    left: "50%",
    transform: "translateX(-50%)",
    bottom: "5px",
    margin: "opx",
    width: "100%",
    '& .Mui-selected': {
        backgroundColor: '#fce022',
      color: 'black',
      borderRadius: '8px',
      '&:hover': {
          backgroundColor: "#fce022"
        }
       },
    '& > *': {
      marginTop: theme.spacing(1),
      fontSize: "16px",
      '& ul li button': {
        fontSize: "15px",
        fontWeight: "500",
        '&*': {
          backgroundColor: "#3288cd"
        }
      },
    },
  },
}));

const NotificationsModal = ({
  currentPage,
  loading,
  pageCount,
  ...props
}) => {
  const classes = useStyles()
  const listRef = React.useRef(null)
  const display_list = props.list.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  const handleScroll = useCallback((e) => {
    if (loading) return
    const top = e.target.scrollTop;
    const height = e.target.scrollHeight;
    const client = e.target.clientHeight;
    const delta = height - top - client;
    const isAtBottom = delta == 0;
    if (isAtBottom) {
      props.getNotificationsList(currentPage + 1)
    }
  }, [currentPage, loading])

  const handleChangePage = (e, page) => {
    listRef.current.scrollTo({top: 0})
    props.getNotificationsList(page, false, true)
  }

  return (
    <div className="notifications-modal">
      <div ref={listRef} onScroll={handleScroll} className="with-scrollbar modal-content">
        {loading && (
          <div className="absoluteCenter">
            <Spinner />
          </div>
        )}
        {display_list.map((item) => (
          <NotificationItem
            getNotificationsList={props.getNotificationsList}
            readNotification={props.readNotification}
            key={item.id}
            item={item}
            closeModal={props.closeModal}
            clearPatient={props.clearPatient}
            currentPage={props.currentPage}
          />
        ))}
      </div>
      <div className={classes.root}>
        <Pagination
          classes={{ ul: classes.ul }}
          count={pageCount}
          color="primary"
          page={currentPage}
          defaultPage={1}
          size='small'
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  notifications: state.notifications,
  currentPage: state?.notifications?.currentPage,
  loading: state?.notifications?.loading,
  pageCount: state?.notifications?.pageCount,
});

const mapDispatchToProps = { clearPatient, readNotification, getNotificationsList };

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsModal);
