import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import { compose } from "redux";
import { v4 } from "uuid";
import api from "../../../api";
import useUpdateEffect from "../../../hooks/useUpdateEffect";

import { getAllChatMessagesTemplates } from "../../../redux/chat/actions";
import { getCurrentConsultation } from "../../../redux/consultations/actions";
import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";
import { setNetworkError } from "../../../redux/networkError/actions";
import {
  getAllPatients,
  getPatientCard,
  getPatientCards,
  getThePatient,
  getThePatientAnamnesis,
  getThePatientAppointments,
  setPatientAsFavourite,
} from "../../../redux/patients/actions";
import { addNotification } from "../../../redux/popups/actions";

import arrowLeft from "../../../style/icons/ArrowLeftLight/arrow-left.svg";
import plus from "../../../style/icons/PlusInCircle/plus.svg";
import shevronDown from "../../../style/icons/ShevronDown/shevron-down.svg";

import Chat from "../../components/Chat/Chat";
import ConsultationCard from "../../components/ConsultattionCard/ConsultationCard";
import DetailAddressModal from "../../components/DetailAddressModal/DetailAddressModal";
import ShowMedCard from "../../components/MedCard/ShowMedCard";
import NewConsultation from "../../components/NewConsultation/NewConsultation";
import RelativesList from "../../components/RelativesList/RelativesList";
import Button from "../../components/ui/Button/Button";
import Spinner from "../../components/ui/Spinner/Spinner";
import NewCardForm from "../../forms/NewCardForm/NewCardForm";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import "./Client.scss";
import { getCurrentConsultSelector } from "../../../redux/consultations/selectors";

const Client = ({
  // patients,
  // id,
  role,
  // history,
  patient,
  patientCard,
  getThePatientAppointments,
  user,
  chat,
  // relatives = [],
  getAllChatMessagesTemplates,
  getThePatient,
  getPatientCards,
  // getPatientCard,
  getThePatientAnamnesis,
  // anamnesis = [],
  cards,
  resetInitialValues,
  getCurrentConsultation,
  setNetworkError,
  attendingDoctor,
  addNotification,
  currentConsultation,
  setPatientAsFavourite,
}) => {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const [newCard, setNewCard] = useState(false);
  const [showRelatives, setShowRelatives] = useState(false);
  const [localPatientRelationShip, setLocalPatientRelationship] = useState(null);
  const [loading, setLoading] = useState();
  const [favouriteLoading, setFavouriteLoading] = useState(false)
  const [details, setDetails] = useState(false);
  const [historyItem, setHistoryItem] = useState(undefined);
  const [showAddress, setShowAddress] = useState(false)

  const patientConsultAddress = {
    province: new URLSearchParams(location.search).get("province"),
    district: new URLSearchParams(location.search).get("district"),
    street: new URLSearchParams(location.search).get("thoroughfare"),
    building: new URLSearchParams(location.search).get("houseNumber"),
    apartment: new URLSearchParams(location.search).get("flatNumber"),
    floor: new URLSearchParams(location.search).get("floor"),
    entrance: new URLSearchParams(location.search).get("entrance"),
    codeOrNote: new URLSearchParams(location.search).get("comment"),
  }

  const consultId = new URLSearchParams(history.location.search).get("consultId");
  const isPersonalDoctor = new URLSearchParams(history.location.search).get("isPersonalDoctor");
  

  useEffect(() => {
    setHistoryItem(undefined);
  }, [params]);

  const addCard = () => {
    setNewCard(true);
  };

  const removeCard = () => {
    setNewCard(false);
  };

  const showRel = () => {
    setShowRelatives(true);
  };

  const replaceAva = (person) => {
    if (person && person.patronymic && person.firstName)
      return (
        person.patronymic[0].toUpperCase() + person.firstName[0].toUpperCase()
      );
  };

  const toUpperCase = (person) => {
    return (
      person.relationShip &&
      person.relationShip.title[0].toUpperCase() +
        person.relationShip.title.slice(1)
    );
  };

  // const setPatientAsFavorite = (patientCardID) => {
  //   setLoading(true);

  //   api.patients
  //     .setPatientAsFavorite(patientCardID)
  //     .then(() => getCurrentConsultation(consultId))
  //     .then(() => setLoading(false));
  // };

  const addNewCard = newCard && (
    <NewCardForm toClose={removeCard} setNewCard={setNewCard} />
  );

  const medicalcard = !newCard && (
    <ShowMedCard
      historyItem={historyItem}
      propsRole={role}
      setHistoryItem={setHistoryItem}
      setDetails={setDetails}
      details={details}
    />
  );

  useUpdateEffect(() => {
    if (patient?.relationShip) {
      setLocalPatientRelationship(patient.relationShip);
    } else {
      setLocalPatientRelationship(null)
    }
  }, [patient]);

  const handleShowAddress = (e) => {
    setShowAddress(prev => !prev)
    if (!showAddress) {
      setShowAddress(true)
    }
  }

  useEffect(() => {
    console.log("MATCH", match.params);
    // if (match.params.patient) {
    getPatientCards(match.params.patient).then((res) => {
      // console.log({ res });
      if (
        res.cards &&
        res.cards.items &&
        res.cards.items.length &&
        res.cards.items.length > 0
      ) {
        getAllChatMessagesTemplates();
        getThePatient(match.params.patient, match.params.card).then(
          ({ patient }) => setLocalPatientRelationship(patient.relationShip)
        );
        // TODO switch by role
        getThePatientAnamnesis(match.params.patient, match.params.card);
        getThePatientAppointments(match.params.card);
        // getPatientCards(match.params.patient);
        consultId &&
          getCurrentConsultation(consultId).then(({ currentConsultation }) => {
            // console.log(
            //   "-------------------------------> Внимание",
            //   currentConsultation
            // );
            if (currentConsultation && currentConsultation.isNeedToPay) {
              // console.log("-------------------------------> Внимание");
              setNetworkError({
                response: {
                  status: t("warning"),
                  statusText: t("consultationIsNotPayed"),
                  hold: true,
                },
              });
            }
          });
      }
    });
    // }
  }, [match.params]);

  useEffect(() => {
    return function () {
      resetInitialValues();
    };
  }, []);

  useEffect(() => {
    return () => {
      localStorage.removeItem("currentConsultationId");
      localStorage.removeItem("isPersonalDoctor");
    };
  }, []);

  const handleGoBack = () => {
    if (history.location?.state?.from) {
      console.log(history.location?.state?.from);
      history.push(history.location.state.from)
    } else {
      console.log(role);
      history.push(`/home/${role}/monitoring?page=1&period=All`)
    }
  }

  
  const handleSetFavourite = (id) => {
    return () => {
      setFavouriteLoading(true)
      setPatientAsFavourite(id)
        .finally(() => setFavouriteLoading(false))
    }
  }

  const isActualCardData = patientCard?.id === Number(params.card)
  const showFavouriteButton = role === "doctor" && patient && patient.attendingDoctor && isActualCardData
  const isFavouritePatient = patient?.attendingDoctor?.length
  const favouriteButtonClassName = `btn favoritePatientBtn ${isFavouritePatient ? "isFavoriteTrue" : "isFavoriteFalse"}`
  const favouriteButtonText = patient?.attendingDoctor?.length ? t("isFavourite") : t("addToFavourite")
  return (
    <div className="doctorWrapper">
      {loading ? (
        <div className="locker">
          <div className="absoluteCenter">
            <Spinner />
          </div>
        </div>
      ) : (
        <>
          <div className="header">
            <div className="clientInfo">
              <div
                className="previousBox"
                onClick={handleGoBack}
              >
                <img className="arrow-left" alt="back arrow" src={arrowLeft} />
              </div>
              {isActualCardData && patient && patient.photoUrl && (
                <img
                  className="doctorImage"
                  src={patient && patient.photoUrl}
                  alt="userImg"
                />
              )}
              {isActualCardData && patient && !patient.photoUrl && (
                <div className={"patientImage"}>{replaceAva(patient)}</div>
              )}
              {!loading && isActualCardData && (
                <div className="doctorDetails">
                  <span className="doctorName">
                    {patient && patient.fullName}
                  </span>
                  {localPatientRelationShip && (
                    <span className="doctorMe">
                      {localPatientRelationShip.title}
                    </span>
                  )}
                  <br />
                  <span className="doctorPhone">{`${t("phone")} ${
                    patient && patient.phone
                      ? patient && patient.phone
                      : t("empty")
                  }`}</span>
                </div>
              )}
                {isActualCardData && !isPersonalDoctor && (
                  <img
                    onClick={showRel}
                    className="arrow-down"
                    alt=""
                    src={shevronDown}
                  />
                )}
                {/* {currentConsultation && (
                  <div className={`consultStatusRecord ${currentConsultation.status}`}>
                    {t(currentConsultation.status)}
                  </div>
                )} */}
                {patientConsultAddress.province && (
                  <div className="patientAddress">
                    <div onClick={handleShowAddress} className="addressText">
                      {t("address")}
                      {showAddress && <KeyboardArrowUpIcon style={{marginLeft: "5px", color: "#4b9bf5"}}/>}
                      {!showAddress && <KeyboardArrowDownIcon style={{marginLeft: "5px", color: "#4b9bf5" }} />}
                    </div>
                    {showAddress && (
                      <DetailAddressModal
                        handleClose={() => setShowAddress(false)}
                        options={patientConsultAddress}
                      />
                    )}
                  </div>
                )}
              {patient &&
                patient.patient &&
                patient.patient.isCompanySubscribed && (
                  <div className="subscriber">
                    <div className="blueCircle">
                      <div className="checkMark" />
                    </div>
                  </div>
                  )}
            </div>
            {showRelatives && (
              <RelativesList
                setHistoryItem={setHistoryItem}
                historyItem={historyItem}
                setShowRelatives={setShowRelatives}
                replaceAva={replaceAva}
                toUpperCase={toUpperCase}
                setDetails={setDetails}
              />
              )}
            {(role === "operator" || role === "insurance-operator") && (
              <Button
                className={"btn alignCenter flex noBackground"}
                withIcon
                icon={plus}
                type="button"
                onClick={addCard}
                text={t("addMedicalCard")}
              />
            )}
            {/* HIDE NOT TO SHOW IN RELEASE */}
            {showFavouriteButton && (
                <Button
                  className={favouriteButtonClassName}
                  icon={plus}
                  type="button"
                  loading={favouriteLoading}
                  onClick={handleSetFavourite(patientCard.id)}
                  text={favouriteButtonText}
                  disabled={isFavouritePatient}
                />
              )}
          </div>
          <div className="doctorContentContainer">
            {addNewCard}
            {medicalcard}
            <Chat userRole={role} user={user} chat={chat} />
            {localStorage.getItem("isPersonalDoctor") !== "true" && (
              <>
                {role === "doctor" && (
                  <ConsultationCard loading={loading} setLoading={setLoading} />
                )}
                {(role === "operator" || role === "insurance-operator") && (
                  <NewConsultation
                    cards={cards}
                    // loading={loading}
                    // setLoading={setLoading}
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ patients, user, chat, ...state }) => ({
  patients,
  attendingDoctor:
    state?.monitoring?.consultations?.currentConsultation?.patientCard
      ?.attendingDoctor,
  patient: patients && patients.patient,
  id:
    patients &&
    patients.patient &&
    patients.patient.patient &&
    patients.patient.patient.id,
  cards: patients && patients.cards && patients.cards.items,
  anamnesis: patients && patients.anamnesis && patients.anamnesis.items,
  patientCard: patients && patients.patient,
  currentConsultation: getCurrentConsultSelector(state),
  user,
  chat,
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, {
    getAllPatients,
    setInitalValues,
    getThePatient,
    getPatientCards,
    getAllChatMessagesTemplates,
    getThePatientAppointments,
    getPatientCard,
    getThePatientAnamnesis,
    resetInitialValues,
    getCurrentConsultation,
    setNetworkError,
    addNotification,
    setPatientAsFavourite,
  })
);



export default enhance(Client);
