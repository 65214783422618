import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import { MessagesList, ChatTools, CallTools } from "./components";
import { connect, useSelector } from "react-redux";
import { PORT_SUPPORT_SOCKET } from "../../../api";
import { useHistory, withRouter, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getConsultationAnamnesis,
  getCurrentConsultation,
  resetCurrentConsultation,
} from "../../../redux/consultations/actions";
import { setNetworkError } from "../../../redux/networkError/actions";
import {
  stopChatCall,
  sendChatMessage,
  getChatHistory,
  // wsConnected,
  // wsDisconnected,
  disconnectCallWS,
  mutedAudioChatCall,
  mutedVideoChatCall,
  clearHistoryChat,
  getChatHistorySocket,
  leaveChatRoom,
} from "../../../redux/chat/actions";
import { SIGNALING_SERVER, PORT_CHAT_SOCKET } from "../../../api/index";
import "react-circular-progressbar/dist/styles.css";
import "./Chat.scss";

const SUPPORT_CHAT_URL = `${SIGNALING_SERVER}${PORT_SUPPORT_SOCKET}/support`;
const CONSULTATION_CHAT_URL = `${SIGNALING_SERVER}${PORT_CHAT_SOCKET}/chat`;

const Chat = ({
  appointments,
  getCurrentConsultation,
  getConsultationAnamnesis,
  cancelOfConsultationFlagSet,
  setNetworkError,
  patientCard,
  doctor,
  bothInChat,
  bothInCall,
  stopChatCall,
  sendChatMessage,
  getChatHistory,
  historyChat,
  disconnectCallWS,
  mutedAudioChatCall,
  mutedVideoChatCall,
  currentConsultation,
  isVideoCall,
  isAudioCall,
  resetCurrentConsultation,
  getChatHistorySocket,
  leaveChatRoom,
  ...props
}) => {
  const { t } = useTranslation();
  const docSpecialty = useSelector(
    (state) =>
      (state.monitoring &&
        state.monitoring.consultations &&
        state.monitoring.consultations.currentConsultation &&
        state.monitoring.consultations.currentConsultation.specialty) ||
      ""
  );

  const [messageValue, setMessageValue] = useState("");
  const [status, setStatus] = useState(true);
  const [messageIdEdit, setMessageIdEdit] = useState(null);
  const history = useHistory();
  const params = useParams()
  const isAttendingDoctor = Boolean(window.localStorage.getItem("isPersonalDoctor"));
  const patientID = new URLSearchParams(history.location.search).get("user");
  const consultId = new URLSearchParams(history.location.search).get("consultId");
  const isPersonalDoctor = Boolean(new URLSearchParams(history.location.search).get("isPersonalDoctor"))
  const role = isPersonalDoctor ? "personalDoctor" : props.userRole

  useEffect(() => {
    return () => {
      // wsDisconnected()
      leaveChatRoom()
      resetCurrentConsultation()
    };
  }, [])

  const updateChatHistory = (page = 0) => {
    getChatHistorySocket({
      userId: isPersonalDoctor ? params.card : patientID,
      consultationId: consultId,
      page,
      role: role,
    });
  }
  
  useEffect(() => { getCurrentConsultation(consultId) }, [consultId])

  useEffect(() => {
    const _status = (currentConsultation && currentConsultation.status) || null;
    setStatus(_status);
  }, [currentConsultation]);

  return (
    <div className={`doctorChat ${isAttendingDoctor ? " favouriteChat" : ""}`}>
      <CallTools
        role={role}
        doctor={props.user.doctor}
        status={status}
        isVideoCall={isVideoCall}
        patientID={patientID}
        consultId={consultId}
        docSpecialty={docSpecialty}
        doctor={doctor}
        currentConsultation={currentConsultation}
      />

      <MessagesList
        role={role}
        t={t}
        videoCall={isVideoCall}
        userId={patientID}
        setMessageValue={setMessageValue}
        setMessageEdit={setMessageIdEdit}
        updateChatHistory={updateChatHistory}
        consultId={consultId}
        status={status}
      />
      <ChatTools
        cardId={params.card}
        // isAttendingDoctor={isAttendingDoctor}
        messageValue={messageValue}
        setMessageValue={setMessageValue}
        setMessageIdEdit={setMessageIdEdit}
        status={status}
        t={t}
        messageIdEdit={messageIdEdit}
        patientID={patientID}
        consultId={consultId}
        videoCall={isVideoCall}
        isAttendingDoctor={isPersonalDoctor}
        currentConsultation={currentConsultation}
        role={role}
      />
    </div>
  );
};

const mapStateToProps = ({ patients, user, monitoring, chat }) => ({
  historyChat: chat.historyChat.items,
  bothInChat: chat.countMembersInRoom,
  isVideoCall: chat.isVideoCall,
  isAudioCall: chat.isAudioCall,
  patientCard: patients && patients.patient,
  appointments: patients && patients.appointments,
  doctor: user,
  currentConsultation: monitoring?.consultations?.currentConsultation,
});

export default withRouter(
  connect(mapStateToProps, {
    getCurrentConsultation,
    getConsultationAnamnesis,
    setNetworkError,
    stopChatCall,
    sendChatMessage,
    getChatHistory,
    // wsConnected,
    // wsDisconnected,
    disconnectCallWS,
    mutedAudioChatCall,
    mutedVideoChatCall,
    resetCurrentConsultation,
    clearHistoryChat,
    getChatHistorySocket,
    leaveChatRoom,
  })(Chat)
);
